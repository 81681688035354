import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { CHECKIN_CATEGORY_STR } from './../../../constants/variables'

export const COLUMN_CONFIG = [
    {
        dataIndex: "ticketCheckInId",
        title: <div>ID</div>,
        fixed: true,
        className: "",
        key: "ticketCheckInId",
        render: (ticketCheckInId) => {
            return <div style={{ minWidth: '30px', display:"flex", alignItems:"flex-end" }} className='text-center'>{ticketCheckInId}</div>
        },
        width: "50px",
        visible: true,
    },
    {
        dataIndex: "staffFullName",
        title: <div><FormattedMessage id="StaffFullName"/></div>,
        fixed: true,
        className: "",
        key: "staffFullName",
        render: (staffFullName) => {
            return <div>{staffFullName}</div>
        },
        width: "50px",
        visible: true,
    },
    {
        dataIndex: "checkinTime",
        title: <div><FormattedMessage id="Time"/></div>,
        fixed: true,
        className: "",
        key: "checkinTime",
        render: (checkinTime) => {
            return <div style={{ minWidth: '76px' }} className="d-flex j-c">{checkinTime ? moment(checkinTime*1000).format('DD/MM/YYYY HH:mm:ss') : ''}</div>
        },
        width: "100px",
        visible: true,
    },
    {
        dataIndex: "checkinCategory",
        title: <div><FormattedMessage id="CheckinCategory"/></div>,
        fixed: true,
        className: "",
        key: "checkinCategory",
        render: (checkinCategory) => {
            return <div>{CHECKIN_CATEGORY_STR[checkinCategory]}</div>
        },
        width: "50px",
        visible: true,
    },
]