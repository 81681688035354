import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { CHECKIN_CATEGORY_STR } from './../../constants/variables'

export const COLUMN_CONFIG = [
    {
        dataIndex: "ticketCheckInId",
        title: <div>ID</div>,
        fixed: true,
        className: "",
        key: "ticketCheckInId",
        render: (ticketCheckInId) => {
            return <div style={{ minWidth: '30px', display:"flex", alignItems:"flex-end" }} className='text-center'>{ticketCheckInId}</div>
        },
        width: "50px",
        visible: true,
    },
    {
        dataIndex: "fullName",
        title: <div><FormattedMessage id="FullName"/></div>,
        fixed: true,
        className: "",
        key: "fullName",
        render: (fullName) => {
            return <div>{fullName}</div>
        },
        width: "150px",
        visible: true,
    },
    {
        dataIndex: "phone",
        title: <div><FormattedMessage id="Phone"/></div>,
        fixed: true,
        className: "",
        key: "phone",
        render: (phone) => {
            return <a rel='noopener noreferrer' href={`mailto:${phone}`}>{phone}</a>
        },
        width: "150px",
        visible: true,
    },
    {
        dataIndex: "email",
        title: <div><FormattedMessage id="Email"/></div>,
        fixed: true,
        className: "",
        key: "email",
        render: (email) => {
            return <a rel='noopener noreferrer' href={`mailto:${email}`}>{email}</a>
        },
        width: "150px",
        visible: true,
    },
    {
        dataIndex: "staffFullName",
        title:  <div><FormattedMessage id="StaffFullName"/></div>,
        fixed: true,
        className: "",
        key: "staffFullName",
        render: (staffFullName) => {
            return <div>{staffFullName ? staffFullName : ''}</div>
        },
        width: "150px",
        visible: true,
    },
    {
        dataIndex: "checkinTime",
        title: <div><FormattedMessage id="Time"/></div>,
        fixed: true,
        className: "",
        key: "checkinTime",
        render: (checkinTime) => {
            return (
                <div style={{ minWidth: '76px' }} className="d-flex j-c">{checkinTime ? moment(checkinTime*1000).format('DD/MM/YYYY HH:mm:ss') : ''}</div>
            )
        },
        width: "150px",
        visible: true,
    },
    {
        dataIndex: "checkinCategory",
        title: <div><FormattedMessage id="CheckinCategory"/></div>,
        fixed: true,
        className: "",
        key: "checkinCategory",
        render: (checkinCategory) => {
            return <div style={{ color: "green" }}>{CHECKIN_CATEGORY_STR[checkinCategory]}</div>
        },
        width: "50px",
        visible: true,
    },
]