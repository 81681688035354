
import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import LayoutPage from "./Page/Layout"
import Login from "./Page/Login"
import Layout from './../src/Manage/Layout'
import Home from "./../src/Manage/Home"
import { toast, ToastContainer } from 'react-toastify'
import DetailTicket from "./../src/Manage/DetailTicket"
import { logout } from './actions/member'
import CheckinHistory from "./../src/Manage/CheckinHistory"

function App(props) {
  const { member = {}, logout } = props

  const { isUserLoggedIn } = member
  function showToast(type, message) {
    // 0 = warning, 1 = success
    switch (type) {
      case 0:
        toast.warning(message)
        break
      case 1:
        toast.success(message)
        break
      default:
        break
    }
  }

  return (
    <>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}
        position={toast.POSITION.BOTTOM_RIGHT}
      />
      <Router>
        <Switch>

          {isUserLoggedIn ? (
            <>
              <Route exact path="/" component={(props) => <Layout {...props} showToast={(type, message) => showToast(type, message)} logout={() => { logout() }} member={member} Component={Home} active="1" />} />
              <Route exact path="/detail/:id" component={(props) => <Layout title="Trở về" isBack={true} {...props} showToast={(type, message) => showToast(type, message)} logout={() => { logout() }} member={member} Component={DetailTicket} active="1" />} />
              <Route exact path="/history" component={(props) => <Layout {...props} showToast={(type, message) => showToast(type, message)} logout={() => { logout() }} member={member} Component={CheckinHistory} active="1" />} />
            </>
          ) : (
              <Route path="/" component={(props) => <LayoutPage  {...props} Component={Login} />} />
            )
          }
        </Switch>
      </Router>
    </>
  );
}
const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = dispatch => {
  return {
    logout: () => logout()
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
