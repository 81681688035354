import React, { useState } from 'react';
import { USER_LOGIN } from '../../constants/memberTypes'
import {
  message,
} from 'antd'
import { useDispatch } from 'react-redux'
import loginService from './../../services/login'

function Login(props) {
  const dispatch = useDispatch()
  const [data, setData] = useState({ username: '', password: '' })

  function signIn() {
    loginService.login(data).then((result) => {
      if (result) {
        const newData = { ...result, accessToken: result?.token, refreshToken: result?.token };
        dispatch({ type: USER_LOGIN, data: newData })
        setTimeout(() => { props.history.push('/') }, 500)
        message.success("Đăng nhập thành công!")
      } else {
        message.warning("Tài khoản hoặc mật khẩu không đúng.")
      }
    })
  }

  function onChange(e = {}) {
    const { value, name } = e.target
    data[name] = value
    setData(data)
  }

  return (
    <div>
      <section id="form">{/*form*/}
        <div className="container">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ minWidth: '325px' }}>
              <div className="login-form">{/*login form*/}
                <h2>Đăng nhập</h2>
                <form action="#">
                  <input style={{ display: 'none' }} type="text" />
                  <input style={{ display: 'none' }} type="password" />
                  <input autoComplete="new-password" onChange={(e) => { onChange(e) }} name="username" type="text" placeholder="Tài khoản" />
                  <input autoComplete="new-password" onChange={(e) => { onChange(e) }} name="password" type="password" placeholder="Mật khẩu" />
                  <span>
                    <input type="checkbox" className="checkbox" />
                    Ghi nhớ
               </span>
                  <button onClick={(e) => { e.preventDefault(); signIn() }} type="submit" className="btn btn-default">Đăng nhập</button>
                </form>
              </div>{/*/login form*/}
            </div>

          </div>
        </div>
      </section> {/*/form*/}
    </div>
  )
}
export default Login;