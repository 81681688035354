
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import 'antd/dist/antd.css';
import store from './store'
import { IntlProviderWrapper } from './utility/context/Internationalization'

ReactDOM.render(
  <Provider store={store}>
    <IntlProviderWrapper>
      <App/>
    </IntlProviderWrapper>
  </Provider>,
  document.getElementById('root')
);

