/* eslint-disable no-plusplus */
// remove empty properties from object recursively
export function removeEmptyProperties(obj) {
    Object.keys(obj || {}).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        removeEmptyProperties(obj[key]);
      }
    });
    return obj;
  }
  
  export function removeZeroProperties(obj) {
    Object.keys(obj || {}).forEach((key) => {
      if (obj[key] === 0) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        removeZeroProperties(obj[key]);
      }
    });
    return obj;
  }
  
  export const countNotEmptyProperties = (obj) => {
    let count = 0;
    let countStartExist = false;
    Object.keys(obj || {}).forEach((key) => {
      let countStart = 0;
      let countEnd = 0;
      if (
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key] !== '' &&
        (typeof obj[key] === 'number' || typeof obj[key] === 'string')
      ) {
        if (key.startsWith('start')) {
          countStart++;
          countStartExist = true;
          count += countStart;
        } else if (key.startsWith('end')) {
          countEnd++;
          count = countStartExist ? count - countEnd + 1 : count + countEnd;
        } else {
          count++;
        }
      } else if (typeof obj[key] === 'object') {
        count += countNotEmptyProperties(obj[key]);
      }
    });
    return count;
  };
  
  export function pick(obj, keys) {
    if (!obj) return obj;
    return Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]]));
  }
  
  export function pickBy(obj, predicate = (v) => v) {
    return Object.entries(obj)
      .filter(([k, v]) => predicate(k, v))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
  