const CONTEXTS = {
    STAFF: 'Staff',
    TICKET: 'ticket'
}

export const ENDPOINTS = {
    // Staff
    STAFF_LOGIN: `${CONTEXTS.STAFF}/loginStaff`,

    // Ticket
    CHECK_HISTORY_LIST: `${CONTEXTS.TICKET}/getCheckHistory`,
}