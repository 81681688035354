
import React from 'react'

function LayoutPage(props) {

  const { Component } = props


  return (
    <>
      <header id="header">{/*header*/}
        <div className="header_top">{/*header_top*/}
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="contactinfo">
                  <img style={{ height: '30px', width: '40px', objectFit: 'contain' }} src="images/logo.png" alt="" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="social-icons pull-right">
                  <ul className="nav navbar-nav">
                    <li style={{ minHeight: 30 }}>

                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>{/*/header_top*/}

      </header>{/*/header*/}
      <Component {...props} />

    </>
  );

}

export default LayoutPage;