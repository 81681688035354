import React, { useState, useEffect } from 'react'
import { Table, Input, Select, notification } from 'antd'
import { COLUMN_CONFIG } from './columns'
import { REQUEST_CONFIG } from '../../constants/request'
import { ENDPOINTS } from '../../constants/endpoints'
import { LOCATION, TYPE, CHECKIN_CATEGORY } from '../../constants/variables'
import HistoryService from '../../services/history'
import { CHECKIN_CATEGORY_STR } from './../../constants/variables'

import { SearchOutlined } from '@ant-design/icons'

import './CheckinHistory.scss'

export default function CheckinHistory() {
    const [visibleColumns, setVisibleColumns] = useState(COLUMN_CONFIG)
    const [selectedRows, setSelectedRows] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)

    const [selectedFilterValues, setSelectedFilterValues] = useState({})
    const [locationList, setLocationList] = useState([])

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (_, sltRows) => {
        setSelectedRows(sltRows);
        },
    };

    function mapper(data, filterName) {
        if (data?.staffId && filterName === LOCATION) {
            return {
                key: data?.staffId,
                value: data?.staffFullName,
            }
        }

        if (data?.checkinCategory && filterName === TYPE) {
            return {
                key: data?.checkinCategory,
                value: data?.checkinCategory,
            }
        }
    }

    const handleLocations = (data) => {
        let locations = data.filter((v,i,a)=>a.findIndex(v2=>['staffId','staffFullName'].every(k=>v2[k] ===v[k]))===i).map(item => mapper(item, LOCATION));
        setLocationList(locations)
    }

    function fetchData() {
        setTableData([]);
        setLoading(true);
        HistoryService.getList(
            {
                [REQUEST_CONFIG.method]: "POST",
                [REQUEST_CONFIG.path]: ENDPOINTS.CHECK_HISTORY_LIST,
            },
            {},
            selectedFilterValues,
            currentPage
        )
        .then((res) => {
            if (res?.statusCode === 200) {
                setTableData(res?.data?.data);
                setTotalRecords(res?.totalRecord);
                setTotalPages(res?.totalPage);
                handleLocations(res?.data?.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error during fetch data:', error);
            notification.error({ message: `${error.message}` });
          });
    }

    // TODO: call api to get data
    useEffect(() => {
        fetchData();
    }, [currentPage, selectedFilterValues])

    return (
        <div style={{ marginBottom: 20, marginTop: 10 }} className='history'>
            <div className="col-12 col-md-12">
                <h3>Lịch sử check-in</h3>
            </div>
            <div className="col-12 col-md-12">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <div className="search-input">
                            <Input size="large" 
                                prefix={<SearchOutlined />}
                                onChange={({ target: { value } }) =>
                                    setSelectedFilterValues({
                                        ...selectedFilterValues,
                                        searchText: value,
                                    })
                                }
                                className="search-input-wrapper"
                                placeholder="Tìm theo tên"
                                value={selectedFilterValues?.searchText}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <Select
                            allowClear
                            className="w-200"
                            placeholder="Lựa chọn vị trí..."
                            value={locationList?.find(x => x.key === selectedFilterValues?.filter?.staffId)?.value}
                            onChange={(key, obj) =>
                            setSelectedFilterValues({
                            ...selectedFilterValues,
                            filter: {
                                staffId : obj?.key
                            },
                            })
                        }>
                            {locationList !== undefined && locationList.map((obj) => (
                                <Select.Option value={obj?.key} key={obj?.key}>
                                    {obj?.value}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-12 col-md-3">
                        <Select
                            allowClear
                            className="w-200"
                            placeholder="Lựa chọn loại..."
                            value={selectedFilterValues?.filter?.checkinCategory}
                            onChange={(key, obj) =>
                            setSelectedFilterValues({
                            ...selectedFilterValues,
                            filter: {
                                checkinCategory : obj?.value
                            },
                            })}>
                            {CHECKIN_CATEGORY !== undefined && CHECKIN_CATEGORY.map((obj) => (
                                <Select.Option value={obj?.value} key={obj?.key}>
                                    {CHECKIN_CATEGORY_STR[obj?.value]}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
                
                <div className="d-flex flex-column align-items-center">
                    <Table
                        id="rawResTable"
                        className="w-100 tableMobile center-table"
                        columns={visibleColumns.filter((item) => item.visible)}
                        dataSource={tableData}
                        rowKey={(record) => Object.values(Object.values(record)[0])[0]}
                        rowSelection={rowSelection}
                        pagination={false}
                        sticky
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    )
}