import { combineReducers } from 'redux'
import member from './member'

import searchData from './search'
import mainData from './product'
import app from './app'
const rootReducer = combineReducers({
  member,
  searchData,
  mainData,
  app
})

export default rootReducer