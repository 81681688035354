
import React, { Component} from 'react'
import { Layout, Menu, Tooltip, Avatar, Badge } from 'antd';
import { MenuOutlined, MenuFoldOutlined, UserOutlined, ProfileOutlined, HistoryOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import logo_Final from '../../images/logo_Final_cut.png';
import { FirebaseRef } from '../../lib/firebase'
import { BackButton } from './BackButton'
import './layout.scss'

const { Header, Sider, Content } = Layout;

class LayoutManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: window.innerWidth < 1025 ? true : false,
      docterList: []
    };
  }

  toggle() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    let ref = FirebaseRef.child(`/reQuest`)
    ref.on('value', snapshot => {
      const data = snapshot.val()
      if (data && typeof (data) === 'object') {
        let newData = []

        Object.keys(data).forEach(key => {
          if (data[key] && data[key].status === 'pending') {
            newData.push({
              ...data[key],
              id: key,
            })
          }
        })
        const newArray = newData.reverse()

        this.setState({
          docterList: newArray
        })
      }
    })
  }


  render() {
    const { Component, history, member, logout, isBack, title } = this.props

    const { docterList } = this.state
    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo logoImg-admin" >
            <Link to={"/"}><img src={logo_Final} alt="F shop" width="60" height="60" style={{ marginBottom: "20px", marginLeft: "10px", objectFit: 'contain' }} /></Link>
            {/* <span className="layout-text">Manage</span> */}
          </div>
          <Menu className="menu-wrapper">
            <Menu.Item onClick={() => { history.push('/') }} key="1">
              <ProfileOutlined />
              <span>Quản lý vé</span>
            </Menu.Item>

            <Menu.Item onClick={() => { history.push('/history') }} key="2">
              <HistoryOutlined />
              <span>Lịch sử check-in</span>
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout>
          <Header style={{ background: '#fff', padding: 0, display: 'flex', alignItems: 'center' }}>
            {
              this.state.collapsed ? (<MenuOutlined className="trigger" onClick={() => { this.toggle() }}></MenuOutlined>) : (<MenuFoldOutlined onClick={() => { this.toggle() }} className="trigger"></MenuFoldOutlined>)
            }
            {isBack ? (<div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}><BackButton {...this.props} title={title || "Back"}></BackButton></div>) : null}

            <div style={{ width: '100%', textAlign: 'right', marginRight: '20px', cursor: 'pointer' }}>
              <span style={{ marginRight: '10px' }}>
                <Badge onClick={() => { history.push(member.role !== 'user' ? '/manage/home' : '/manage/circle-user') }} count={docterList.length}>
                  <Avatar src={member.imgUrl || ''} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                </Badge>
              </span>
              <span onClick={() => { history.push('/'); logout(); }}>
                <Tooltip title="Log out">Hi, {member.email && member.email !== '' ? member.email : member.email}  </Tooltip>
              </span>

            </div>
          </Header>
          <Content
            className="contentParent"
          >
            <Component {...this.props} member={member} />
          </Content>
        </Layout>
      </Layout >
    );
  }
}

export default LayoutManage;