import React, { Component } from 'react';
import { Table, Tag, Select, Input, message } from 'antd';
import RequestServer from './../../services/request'
import moment from 'moment'
import './index.scss'
import { number_to_price } from './../../helper/common'
const { Option } = Select;

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pagination: {
        skip: 0,
        limit: 20,
        filter: {},
        total: 0
      },
      loading: false,
      summary: [],
      filterKey: "customer_first_name",
      filterValue: "",
      filter: {}
    };
    this.myTimeout = null

  }

  componentDidMount() {
    this.fetchSummary()
    this.fetch(
      {
        filter: {},
        limit: 20,
        skip: 0
      }
    );
  }

  handleTableChange(paginationData) {
    const { pagination } = this.state
    this.fetch({
      filter: pagination.filter || {},
      limit: paginationData.pageSize,
      skip: (paginationData.current - 1) * paginationData.pageSize
    })
  };

  handleSearch() {
    const { filter } = this.state

    if (this.myTimeout) {
      clearTimeout(this.myTimeout)
    }
    const that = this
    this.myTimeout = setTimeout(() => {

      const newFilter = {}
      Object.keys(filter).forEach(key => {
        if (filter[key] && filter[key] !== "") {
          newFilter[key] = filter[key]
        }
      });
      that.fetch(
        {
          filter: newFilter,
          limit: 20,
          skip: 0
        }
      );
    }, 1000)
  }

  fetchSummary() {
    RequestServer.send({
      method: 'GET',
      path: 'order/getItemSummary',
    }).then((res = {}) => {
      const { data } = res

      if (data && Array.isArray(data)) {
        this.setState({
          summary: data
        })
      }
    });
  }

  fetch = (params = { limit: 20, skip: 0 }) => {
    this.setState({ loading: true });
    RequestServer.send({
      method: 'POST',
      path: 'order/getOrderList',
      data: params,
    }).then((res = {}) => {
      const { data } = res

      if (data && data.orders) {
        this.setState({
          loading: false,
          data: data.orders,
          pagination: {
            ...params,
            skip: data.skip,
            limit: data.limit,
            total: data.total
          },
        });
      } else {
        this.setState({
          loading: false,
          data: [],
          pagination: {
            ...params,
            skip: 0,
            limit: 0,
            total: 0
          },
        })
        // message.warning("Không tìm thấy dự liệu !")
      }
    });
  };

  handleReSendMail(item) {
    let path = 'email/payOrder'
    if (item.shipping_method === 'offline') {
      path = 'email/newOrder'
    }

    RequestServer.send({
      method: 'POST',
      path: path,
      data: {
        targets: 'all',
        orderId: item.id
      }
    }).then((res = {}) => {
      message.success("Gửi lại mail thành công")
    });
  }

  handleRenderText(status) {
    if (status === 'offline') {
      return "Thanh toán tại văn phòng"
    } else if (status === 'online') {
      return "Thanh toán qua VNPAY"
    } else if (status === 'momo') {
      return "Thanh toán qua MoMo"
    } else if (status === 'cod') {
      return "Nhận vé qua bưu điện"
    }
    return "Chuyển khoản ngân hàng"
  }

  render() {
    const { data, pagination, loading, summary } = this.state;
    const columns = [
      // {
      //   title: 'Họ',
      //   dataIndex: 'customer_last_name',
      //   // sorter: (a, b) => a.customer_last_name.length - b.customer_last_name.length,
      //   // ellipsis: true,
      // },
      {
        title: 'Họ Tên',
        dataIndex: 'customer_first_name',
        // sorter: (a, b) => a.customer_first_name.length - b.customer_first_name.length,
        // ellipsis: true,
      },
      // {
      //   title: 'Năm sinh',
      //   dataIndex: 'customer_birthday',
      //   // sorter: (a, b) => {
      //   //   if (moment(a.customer_birthday).isBefore(moment(b.customer_birthday))) {
      //   //     return -1;
      //   //   }
      //   //   return 1;
      //   // },
      //   // ellipsis: true,
      //   render: (text, record) => {
      //     return moment(record.customer_birthday).format('L')
      //   }
      // },
      {
        title: 'Email',
        dataIndex: 'customer_email',
        // sorter: (a, b) => a.customer_email.length - b.customer_email.length,
        // ellipsis: true,
        render: (text, record) => {
          return <a rel='noopener noreferrer' href={`mailto:${record.customer_phone_number}`}>{record.customer_email}</a>
        }
      },
      {
        title: 'SĐT',
        dataIndex: 'customer_phone_number',
        // sorter: (a, b) => a.customer_phone_number.length - b.customer_phone_number.length,
        // ellipsis: true,
        render: (text, record) => {
          return <a rel='noopener noreferrer' href={`tel:${record.customer_phone_number}`}>{record.customer_phone_number}</a>
        }
      },
      {
        title: 'Địa chỉ',
        dataIndex: 'customer_address',
        render: (text, record) => {
          return <div >
            {record.customer_address}
          </div>
        }
      },
      {
        title: 'Trạng thái',
        dataIndex: 'payment_status',
        // sorter: (a, b) => a.payment_status.length - b.payment_status.length,
        // ellipsis: true,
        render: (text, record) => {
          return <div style={{ color: record.payment_status === "paid" ? 'green' : 'red' }}>
            {record.payment_status === "paid" ? 'Đã thanh toán' : 'Chưa thanh toán'}
          </div>
        }
      },
      {
        title: 'Phương thức',
        dataIndex: 'shipping_method',
        render: (text, record) => {
          return <div >
            {this.handleRenderText(record.shipping_method)}
          </div>
        }
      },
      {
        title: 'Tổng tiền',
        dataIndex: 'grand_total',
        // sorter: (a, b) => a.grand_total - b.grand_total,
        render: (text, record) => {
          return <div>
            {number_to_price(record.grand_total || 0)} VND
          </div>
        }
      },
      {
        title: 'Ngày đặt hàng',
        dataIndex: 'created_at',
        // sorter: (a, b) => {
        //   if (moment(a.created_at).isBefore(moment(b.created_at))) {
        //     return -1;
        //   }
        //   return 1;
        // },
        render: (text, record) => {
          return moment(record.created_at).format('L')
        }
      },
      {
        title: '',
        key: 'action',
        fixed: 'right',
        width: '200px',
        render: (text, record) => {
          return (
            <span style={{ display: "flex", }}>

              <Tag onClick={() => {
                this.props.history.push(`/detail/${record.id}`)
              }} color={"orange"} key={"edit"}>
                Cập nhật
              </Tag>
              <Tag onClick={() => {
                this.handleReSendMail(record)
              }} color={"blue"} key={"edit"}>
                Gửi lại mail
              </Tag>
            </span >
          )
        },
      },
    ];

    return (
      <>
        <div style={{ marginBottom: 20 }} className="row home">
          {summary.map((item, index) => {
            return (
              <div style={{ marginBottom: 10 }} className="col col-md-2" key={`${item.name}-${index}`}>
                <div className="card" >
                  <span className="card__name">{item.name}: </span> <span className="card__des">{item.count ? item.count : 0} / {item.max_stock} vé</span>
                </div>
              </div>
            )
          })}
        </div>

        <div style={{ marginBottom: 20 }} className="row">
          <div className="col-12 col-md-4">
            <h3>Quản lý vé</h3>
          </div>
          <div className="col-12 col-md-8">
            <div className="row">
              <div className="col-12 col-md-3">
                <Select onChange={(value) => {
                  const { filter } = this.state
                  this.setState({

                    filter: {
                      ...filter,
                      shipping_method: value
                    }
                  }, () => {
                    this.handleSearch()
                  })
                }} placeholder="Phương thức" style={{ width: '100%' }}>
                  <Option value="">Tất cả phương thức</Option>
                  <Option value="offline">Thanh toán tại văn phòng</Option>
                  <Option value="online">Thanh toán qua VNPAY</Option>
                  <Option value="momo">Thanh toán qua MoMo</Option>
                  <Option value="bank">Chuyển khoản ngân hàng</Option>
                </Select>
              </div>
              <div className="col-12 col-md-3">
                <Select onChange={(value) => {
                  const { filter } = this.state
                  this.setState({
                    filter: {
                      ...filter,
                      payment_status: value
                    }
                  }, () => {
                    this.handleSearch()
                  })
                }} placeholder="Trạng thái" style={{ width: '100%' }}>
                  <Option value="">Tất cả trạng thái</Option>
                  <Option value="paid">Đã thanh toán</Option>
                  <Option value="unpaid">Chưa thanh toán</Option>
                </Select>
              </div>
              <div className="col-12 col-md-6">
                <Input.Group compact>
                  <Select onChange={(value) => {
                    const { filter, filterValue } = this.state
                    this.setState({
                      filterKey: value,
                      filter: {
                        ...filter,
                        [value]: filterValue
                      }
                    }, () => {
                      this.handleSearch(filterValue)
                    })
                  }} defaultValue={"customer_first_name"} style={{ width: '30%' }}>
                    <Option value="customer_last_name">Họ</Option>
                    <Option value="customer_first_name">Tên</Option>
                    <Option value="customer_email">Email</Option>
                    <Option value="customer_phone_number">SĐT</Option>
                    <Option value="qrCode">QR Code</Option>
                  </Select>
                  <Input
                    onChange={(e) => {
                      const { filterKey, filter } = this.state
                      const { value } = e.target
                      this.setState({
                        filterValue: value.trim(),
                        filter: {
                          ...filter,
                          [filterKey]: value.trim()
                        }
                      }, () => {
                        this.handleSearch()
                      })
                    }}
                    style={{ width: '70%' }}
                    placeholder="Tìm kiếm"
                    options={[{ value: 'text 1' }, { value: 'text 2' }]}
                  />
                </Input.Group>
              </div >
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            total: pagination.total || 0,
            current: pagination.skip ? (pagination.skip / pagination.limit) + 1 : 1,
            pageSize: pagination.limit || 20,
            showSizeChanger: true
          }}
          // onShowSizeChange={this.handleonShowSizeChange}
          onChange={(paginationData) => { this.handleTableChange(paginationData) }}
          scroll={{ x: 1300 }}
          rowKey={record => record.id}
        />
        {/* <div style={{ marginTop: 10, textAlign: 'right', fontSize: 25, marginBottom: 20 }}>
          <span
            onClick={() => {
              const newPagination = pagination
              if (newPagination.skip > 0) {
                newPagination.skip -= 1
                this.fetch(newPagination)
              } else {
                message.warning("Bạn đang ở trang đầu !")
              }

            }}
            data-toggle="tooltip" data-placement="top" title="Trang trước đó" style={{ marginRight: 20, cursor: 'pointer' }}
          >{'<'}</span>
          <span onClick={() => {
            const newPagination = pagination
            newPagination.skip += 1
            this.fetch(newPagination)
          }} data-toggle="tooltip" data-placement="top" title="Trang kế tiếp" style={{ cursor: 'pointer' }}>{'>'} </span> </div>
     */}
      </>
    );
  }
}