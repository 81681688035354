import Request from './request'
import { ENDPOINTS } from './../constants/endpoints'

export default class loginService {
    static async login(input = {}) {
        return new Promise((resolve, reject) => {
            Request.send({
                method: 'POST',
                path: ENDPOINTS.STAFF_LOGIN,
                data: input
            }).then((result) => {
                resolve(result?.data)
            }).catch((error) => {
                console.error('Error during login:', error);
            });
        });
        
    }
}