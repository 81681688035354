import React, { useState, useEffect } from 'react';
import { Table, Tag, InputNumber, message } from 'antd';
import RequestService from '../../../services/request'
import moment from 'moment'
import { number_to_price } from '../../../helper/common'
import DatePickerButton from '../../../components/DatePickerButton'
import Text from 'antd/lib/typography/Text';
import * as _ from 'lodash';

export default function OrderDetail({ props }) {
  const { match } = props
  const { id = "" } = match.params
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [order, setOrder] = useState({})

  useEffect(() => {
    RequestService.send({
      method: 'POST',
      path: 'order/getOrderDetail',
      data: { orderId: id }
    }).then((res = {}) => {
      const { data: newData } = res
      if (newData) {
        setData(newData)
        const tmpOrder = {
          id: newData.id,
          payment_status: newData.payment_status,
          customer_email: newData.customer_email,
          customer_first_name: newData.customer_first_name,
          customer_last_name: newData.customer_last_name,
          customer_phone_number: newData.customer_phone_number,
          customer_birthday: moment(newData.customer_birthday),
          shipping_method: newData.shipping_method,
          payment_time: moment(newData.payment_time),
        }
        setOrder(tmpOrder)
        setLoading(false)
      }

    });
  }, [id]);

  function copyToClipboard(text) {
    console.log('text', text)
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  function handleUpdateItem(index) {
    const newData = data.order_items[index]
    if (newData) {
      const tmpData = {
        id: newData.id,
        qty_shipped: newData.qty_shipped,
      }

      RequestService.send({
        method: 'POST',
        path: 'order/updateOrderItem',
        data: tmpData
      }).then((res = {}) => {
        message.success("Cập nhật sản phẩm thành công!")
      });
    }

  }

  function handleUpdateCheckIn(value, record, index) {
    const data = {
      ticketStatus: value ? 1 : -1,
      checkinTime: value ? value.toISOString() : ''
    }
    RequestService.send({
      method: 'POST',
      path: 'Ticket/updateById',
      data: {
        id: record.ticketId,
        data
      }
    }).then((res = {}) => {
      if (res.statusCode === 200) {
        record.checkin_status = data.ticketStatus;
        record.checkin_time = data.checkinTime;
        message.success("Check in thành công!");
        setData((data) => {
          data.order_items.splice(index, 1, record);
          return _.cloneDeep(data);
        });
      }
    })
  }

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
    },
    {
      title: 'Số lượng đặt',
      dataIndex: 'qty_ordered',
      key: 'qty_ordered',
      width: '10%',
    },
    {
      title: 'Số lượng đã giao',
      dataIndex: 'qty_shipped',
      key: 'qty_shipped',
      width: '10%',
      render: (text, record, index) => {
        return <InputNumber style={{ width: '100%' }} min={0} defaultValue={record.qty_shipped} onChange={(value) => {
          const newData = { ...data }
          if (newData.order_items) {
            newData.order_items[index].qty_shipped = value
            setData(newData)
          }
        }} />
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '10%',
      render: (text, record) => {
        return <div style={{ color: record.payment_status === "paid" ? 'green' : 'red' }}>
          {record.payment_status === "paid" ? 'Đã thanh toán' : 'Chưa thanh toán'}
        </div>
      }
    },
    {
      title: 'Thời điểm Check-in',
      dataIndex: 'checkin_status',
      width: '20%',
      key: 'checkin_status',
      render: (text, record, index) => {
        return <div style={{ color: record.checkin_status && record.checkin_status === 1 ? 'green' : 'red', display: 'flex' }}>
          {record.checkin_status && record.checkin_status === 1
            ? <DatePickerButton time={record.checkin_time} onChange={(value) => handleUpdateCheckIn(value, record, index)} />
            : <div style={{ display: 'flex' }}>
              <Text type='danger' style={{ marginRight: '8px', position: 'absolute' }}>Chưa check-in</Text>
              <DatePickerButton time={record.checkin_time} onChange={(value) => handleUpdateCheckIn(value, record, index)} />
            </div>}
        </div>
      }
    },
    {
      title: 'QR Code',
      dataIndex: 'coupon_code',
      key: 'coupon_code',
      width: '15%',
      render: (text, record) => {
        return <div className="coupon_code" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(record.coupon_code) }} data-toggle="tooltip" data-placement="top" title={record.coupon_code}>
          {record.coupon_code}
        </div>
      }
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: '100px',
      render: (text, record, index) => (
        <span style={{ display: "flex", }}>

          <Tag onClick={() => {
            handleUpdateItem(index)
          }} color={"orange"} key={"edit"}>
            Cập nhật
          </Tag>
        </span >
      ),
    },
  ];

  return (
    <div>
      <h4 style={{ display: 'flex' }}>Thông tin đơn hàng
        <div style={{ marginLeft: 'auto' }}>
          {number_to_price(data.grand_total || 0)} VND
        </div></h4>
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={data && data.order_items ? data.order_items : []}
        loading={loading}
        pagination={false}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};