
export const config = {
  apiKey: "AIzaSyCWyNL_le_9NPPMWz7WygeTAh5jPJ--l-Q",
  authDomain: "angelodatanetwork.firebaseapp.com",
  databaseURL: "https://angelodatanetwork.firebaseio.com",
  projectId: "angelodatanetwork",
  storageBucket: "angelodatanetwork.appspot.com",
  messagingSenderId: "632226987703",
  appId: "1:632226987703:web:f07137eb95e72419c55732",
  measurementId: "G-2LVT2LDF4B"
}

