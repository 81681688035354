
import { Firebase, FirebaseRef, FirebaseAdmin } from '../lib/firebase'
import { USER_DETAILS_UPDATE, USER_LOGIN, USER_RESET } from '../constants/memberTypes'
import store from '../store'

/**
  * Sign Up to Firebase
  */
export function signUp(formData) {
  const {
    email,
    password,
    nickname,
    role
  } = formData

  return new Promise(async (resolve, reject) => {
    // Validation rules
    // Go to Firebase

    return Firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((res) => {
        // Send user details to Firebase database

        if (res && res.user.uid) {
          const data = {
            nickname,
            signedUp: Firebase.database.ServerValue.TIMESTAMP,
            lastLoggedIn: Firebase.database.ServerValue.TIMESTAMP,
            email: email,
            uid: res.user.uid,
            role,
            followIds: [],
            groupIds: []
          }


          FirebaseRef.child(`users/${res.user.uid}`).set(data)

        }
        return resolve({ isSuccess: true })
      }).catch((err) => {
        return resolve({ isSuccess: false, err })
      })
  }).catch((err) => {
    throw err.message
  })
}

/**
  * Get this User's Details
  */
function getUserData(dispatch, uid) {
  let UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null

  if (!UID) return false
  if (uid) {
    UID = uid
  }

  const ref = FirebaseRef.child(`users/${UID}`)

  return ref.on('value', (snapshot) => {
    const userData = snapshot.val() || {}
    if (Object.keys(userData).length > 0) {
      userData.uid = UID
      return dispatch({ type: USER_DETAILS_UPDATE, data: userData })
    }
    return { isSuccess: false }
  })
}

export function setMemberData(uid, isExist, email) {
  return dispatch => new Promise((resolve) => {
    return resolve(getUserData(dispatch, uid))
  })
}

export function fetchExistEmail(email) {
  return new Promise(resolve => {
    const ref = FirebaseRef.child('users').orderByChild('email').equalTo(email)
    ref.on('value', (snapshot) => {
      const data = snapshot.val() || {}
      if (Object.keys(data).length > 0) {
        return resolve({ isExist: true })
      }
      return resolve({ isExist: false })
    })

  })

}

export function getMemberData() {

  if (Firebase === null) return () => new Promise(resolve => resolve())

  // Ensure token is up to date
  return dispatch => new Promise((resolve) => {
    Firebase.auth().onAuthStateChanged((loggedIn) => {
      if (loggedIn) {
        return resolve(getUserData(dispatch))
      }
      return () => new Promise(() => resolve())
    })
  })
}

/**
  * Login to Firebase with Email/Password
  */
export function login(formData) {
  const { email, password } = formData

  return new Promise(async (resolve, reject) => {

    // Go to Firebase
    return Firebase.auth().setPersistence(Firebase.auth.Auth.Persistence.LOCAL)
      .then(() => Firebase.auth().signInWithEmailAndPassword(email, password)
        .then(async (res) => {
          const userDetails = res && res.user ? res.user : null

          if (userDetails.uid) {
            // Update last logged in data
            FirebaseRef.child(`users/${userDetails.uid}`).update({
              lastLoggedIn: Firebase.database.ServerValue.TIMESTAMP,
            })

            // Send verification Email when email hasn't been verified
            if (userDetails.emailVerified === false) {
              Firebase.auth().currentUser.sendEmailVerification()
                .catch(() => console.log('Verification email failed to send'))
            }

            // Get User Data from DB (different to auth user data)
            getUserData(store.dispatch)
          }
          store.dispatch({ type: USER_LOGIN, data: userDetails })
          return resolve({ isSuccess: true })
        }).catch(err => {
          return resolve({ isSuccess: false, err })
        }))
  }).catch((err) => {
    throw err.message
  })
}

/**
  * Reset Password
  */
export function resetPassword(formData) {
  const { email } = formData

  return new Promise(async (resolve, reject) => {
    // Validation rules

    console.log(email)
    // Go to Firebase
    return Firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        store.dispatch({ type: USER_RESET })
        return resolve({ isSuccess: true })
      }
      )
      .catch(err => {
        return resolve({ isSuccess: false, err })
      })
  }).catch((err) => {
    throw err.message
  })
}

/**
  * Update Profile
  */
export function updateProfile(formData) {
  const {
    email, password, changeEmail, changePassword
  } = formData

  return dispatch => new Promise(async (resolve, reject) => {
    // Are they a user?
    const UID = Firebase.auth().currentUser.uid

    // Go to Firebase
    return FirebaseRef.child(`users/${UID}`).update(formData)
      .then(async () => {
        // Update Email address
        if (changeEmail) {
          await Firebase.auth().currentUser.updateEmail(email).catch(reject)
        }

        // Change the password
        if (changePassword) {
          await Firebase.auth().currentUser.updatePassword(password).catch(reject)
        }

        // Update Redux
        return resolve(getUserData(dispatch))
      }).catch(reject)
  }).catch((err) => {
    throw err.message
  })
}

/**
  * Logout
  */
export function logout() {
  store.dispatch({ type: USER_RESET })
}

export function getListMember(limit = 100, type = 'email', value = '') {
  return new Promise(resolve => {
    let ref = FirebaseRef.child('users')

    if (limit) {
      ref = ref.limitToLast(limit)
    }
    if (type && value) {
      ref = ref.orderByChild(type).equalTo(value)
    }
    let newData = []
    ref.on('value', snapshot => {
      const data = snapshot.val()
      if (data && typeof (data) === 'object') {
        Object.keys(data).forEach(key => {
          newData.push({
            ...data[key],
            id: key,
          })
        })
        const newArray = newData.reverse()
        return resolve({ isSuccess: true, data: newArray })
      }
      else {
        return resolve({ isSuccess: false, data })
      }

    })

  })
}

export function removeAuthorByID(id) {
  FirebaseAdmin.auth().deleteUser(id).then((result
  ) => {
    console.log(result)


  }).catch(err => {
    console.log('Error deleting user:', err);

  })
}

export function removeMember(id) {
  return new Promise(resolve => {

    // removeAuthorByID(id)

    FirebaseRef.child(`users/${id}`).remove().then((result) => {

      return resolve({ isSuccess: true })
    }).catch(err => {

      return resolve({ isSuccess: false, message: err.message })
    })

  })
}

export function getMember(id) {
  return new Promise(resolve => {
    const ref = FirebaseRef.child(`users/${id}`)

    ref.on('value', snapshot => {
      const data = snapshot.val() || {}

      return resolve({ isSuccess: true, data })
    })
  })
}

export function createMember(formData) {
  if (Firebase === null) return () => new Promise(resolve => resolve())

  return new Promise(resolve => {
    Firebase.auth().onAuthStateChanged(() => {

      return new Promise(() => {

        const data = {
          signedUp: Firebase.database.ServerValue.TIMESTAMP,
          lastLoggedIn: Firebase.database.ServerValue.TIMESTAMP,
          ...formData
        }
        FirebaseRef.child(`users/${data.uid}`).set(data)
          .then(() => {
            return resolve({ isSuccess: true, key: data.uid })
          })
          .catch(err => {
            return resolve({ isSuccess: false, message: err.message })
          })
      })

    })
  })
}

export function updateMember(id, formData) {

  return new Promise(resolve => {

    const ref = FirebaseRef.child(`users/${id}`)
    ref.set({
      ...formData
    }).then(() => {
      store.dispatch({ type: USER_DETAILS_UPDATE, data: formData })

      return resolve({ isSuccess: true })
    }).catch(err => {
      return resolve({ isSuccess: false, message: err.message })
    })

  })
}