import { notification } from 'antd'
import { isEmptyObject } from 'jquery'
import { REQUEST_CONFIG } from './../constants/request'
import { resListResponse } from './../constants/response'
import { removeEmptyProperties } from './../helper/objects'
import requestService from './request'

const FILTER_OBJECT_PAGE_SIZE = 20;

export default class HistoryService {
  static async getList(requestConfig = REQUEST_CONFIG, filter, filterObject, page = 1, id) {
    // handle empty filter properties
    if (!isEmptyObject(filterObject)) {
      removeEmptyProperties(filterObject);
    }

    // handle pagination for api request
    const skip = page * FILTER_OBJECT_PAGE_SIZE;
    const body = {
      skip,
      limit: FILTER_OBJECT_PAGE_SIZE,
      filter: filter,
      ...filterObject,
    };

    const response = await requestService
      .send({ ...requestConfig, data: body })
      .then(
        (res = resListResponse) =>
          !res?.error && {
            statusCode: res?.statusCode,
            data: res?.data,
            totalRecord: res.data?.total,
            totalPage: Math.ceil(res.data?.total / FILTER_OBJECT_PAGE_SIZE)
          }
      )
      .catch((err) => {
        console.log(err.message);
        notification.error({ message: 'Đã có lỗi xảy ra' });
      });

    return response;
  }
}
