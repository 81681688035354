import moment from 'moment'
const appModel = {
  app_img: '',
  title: '',
  subtitle: '',
  description: '',
  start_date: moment(),
  amount: '10',
  app_video: [],
  story: '',
  location: '',
  category: '2',
  user_rate: 10,
  user_view: 100000,
  version: '1.0.0',
  message_tip: 'You will be provided with a temporarily login credential to test use the app in 15 days.',
  desktop: false,
  link_desktop: '',
  ios: false,
  link_ios: '',
  android: false,
  link_android: '',
  web: false,
  link_web: "",
  free: false,
  is_product: true,
  app_ids: [],
  app_file: [],
  status: 'pendding'
}
export default appModel