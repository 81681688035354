import { Button, DatePicker, TimePicker } from 'antd';
import React, { useState } from 'react';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import moment from 'moment';
import './style.scss';

function DatePickerButton(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <DatePicker open={open} onChange={(value) => {
        props.onChange(value);
        setOpen(false);
      }}
        format="YYYY/MM/DD HH:mm:ss"
        className={props.time ? '' : 'hidden-date'}
        showTime={<TimePicker defaultValue={moment(props.time, 'HH:mm:ss')} />}
        defaultValue={moment(props.time)} />
      <Button onClick={() => setOpen((prev) => !prev)}>
        <CalendarOutlined />
      </Button>
    </>
  )
}

export default DatePickerButton;
