import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { HOST } from './../constants/url'

import {
  getQueryString,
} from '../helper/common'

function send({
  method = 'get', path, data = null, query = null, headers = {}, newUrl
}) {
  return new Promise((resolve) => {
    let url = HOST + `${path}${getQueryString(query)}`
    if (newUrl) {
      url = `${newUrl}${getQueryString(query)}`
    }

    const token = window.localStorage.getItem('accessToken');

    if (token) {
      const newToken = token.replace(/"/g, '');
      headers.Authorization = `Bearer ${newToken}`;
    }
    
    axios({
      method, url, data, headers,
    })
      .then((result) => {
        const data = result?.data
        return resolve(data)
      })
      .catch((error) => {
        if (!error) {
          message.error('Something was wrong when request!')
        } else {
          const { response = {} } = error;
          const result = response.data ? response.data : null;
  
          const { statusCode } = result;

          if (statusCode === 401 && result?.message === 'Unauthorized') {
            message.error('Tài khoản truy cập không hợp lệ. Vui lòng đăng nhập lại!')
            setTimeout(() => {
              window.localStorage.clear()
              window.location.href = '/login'
            }, 2000);
          } else if (
            (statusCode === 401 && result?.message === 'TokenExpired') ||
            (statusCode === 401 && result?.message === 'Expired token received for JSON Web Token validation')) {
              message.error('Token đã hết hạn. Vui lòng đăng nhập lại!')
              setTimeout(() => {
                window.localStorage.clear()
                window.location.href = '/login'
              }, 2000);
          } else if ((statusCode === 403 && result?.message === 'InvalidToken')) {
            message.error('Token không hợp lệ. Vui lòng đăng nhập lại!')
            setTimeout(() => {
              window.localStorage.clear()
              window.location.href = '/login'
            }, 2000);
          }
          else {
            return resolve(result?.data)
          }
        }
      })
  })
}

export default {
  send,
}
