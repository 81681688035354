import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Tabs,
  message,
  Select,
} from "antd";
import RequestServer from "../../services/request";
import moment from "moment";
import * as _ from "lodash";
import OrderDetail from "./OrderDetail/index";
import UserCheckinHistory from "./UserCheckinHistory";
import {
  UserOutlined,
  HistoryOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

import "./DetailTicket.scss";
const { Option } = Select;

export default function DetailTicket(props) {
  const [currentTab, setCurrentTab] = useState("userDetail");

  const changeTab = (tab) => {
    setCurrentTab(tab);
  };

  const listTab = [
    {
      label: "Thông tin đơn hàng",
      key: "userDetail",
      icon: <OrderedListOutlined width={16} height={16} />,
      component: <OrderDetail props={props} />,
    },
    {
      label: "Lịch sử check-in",
      key: "checkinHistory",
      icon: <HistoryOutlined width={16} height={16} />,
      component: <UserCheckinHistory props={props} />,
    },
  ];

  const { match } = props;
  const { id = "" } = match.params;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [order, setOrder] = useState({});
  const onFinish = (values) => {
    const newData = {
      ...values,
      customer_birthday: moment(values.customer_birthday).format("yyyy/MM/DD"),
      id: data.id,
    };
    RequestServer.send({
      method: "POST",
      path: "order/updateOrder",
      data: newData,
    }).then((res = {}) => {
      if (res.statusCode === 200) {
        message.success("Cập nhật thông tin khách hàng thành công!");
      }
    });
  };

  useEffect(() => {
    RequestServer.send({
      method: "POST",
      path: "order/getOrderDetail",
      data: { orderId: id },
    }).then((res = {}) => {
      const { data: newData } = res;
      if (newData) {
        setData(newData);
        const tmpOrder = {
          id: newData.id,
          payment_status: newData.payment_status,
          customer_email: newData.customer_email,
          customer_first_name: newData.customer_first_name,
          customer_last_name: newData.customer_last_name,
          customer_phone_number: newData.customer_phone_number,
          customer_address: newData.customer_address,
          customer_birthday: moment(newData.customer_birthday),
          shipping_method: newData.shipping_method,
          payment_time: moment(newData.payment_time),
        };
        setOrder(tmpOrder);
        setLoading(false);
      }
    });
  }, [id]);

  return (
    <>
      <div className="detail">
        <div className="detail-user-header">
          <span style={{ marginRight: "10px" }}><UserOutlined width={16} height={16} /></span>
          <span>Thông tin người dùng</span>
        </div>
        <div className="detail-user-body">
          {!loading ? (
            <Form
              initialValues={order}
              layout="vertical"
              name="nest-messages"
              onFinish={onFinish}
            >
              <div className="row">
                <div className="col col-md-4">
                  <Form.Item
                    name={"customer_first_name"}
                    label="Họ và Tên *"
                    rules={[
                      {
                        required: true,
                        message: "Trường này không được để trống !",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div style={{ display: "none" }} className="col col-md-4">
                  <Form.Item
                    name={"customer_last_name"}
                    label="Tên *"
                    rules={[
                      {
                        required: true,
                        message: "Trường này không được để trống !",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="col col-md-4">
                  <Form.Item
                    name={"customer_email"}
                    label="Email *"
                    rules={[
                      {
                        required: true,
                        message: "Trường này không được để trống !",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="col col-md-4">
                  <Form.Item name={"customer_phone_number"} label="SĐT">
                    <Input />
                  </Form.Item>
                </div>
                <div className="col col-md-4">
                  <Form.Item name={"customer_address"} label="Địa chỉ giao hàng">
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-4">
                  <Form.Item name={"payment_status"} label="Trạng thái">
                    <Select placeholder="Trạng thái" style={{ width: "100%" }}>
                      <Option value="paid">Đã thanh toán</Option>
                      <Option value="unpaid">Chưa thanh toán</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col col-md-4">
                  <Form.Item name={"shipping_method"} label="Phương thức">
                    <Select placeholder="Phương thức" style={{ width: "100%" }}>
                      <Option value="offline">Thanh toán tại văn phòng</Option>
                      <Option value="online">Thanh toán qua VNPAY</Option>
                      <Option value="momo">Thanh toán qua MoMo</Option>
                      <Option value="bank">Chuyển khoản ngân hàng</Option>
                      <Option value="cod">Nhận vé qua bưu điện</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Cập nhật
                </Button>
              </Form.Item>
            </Form>
          ) : null}
        </div>

        <div className="detail-ticket-wrapper">
          <Tabs
            defaultActiveKey={currentTab}
            className="tabs"
            onChange={(key) => changeTab(key)}
          >
            {listTab.map((item, index) => {
              return (
                <Tabs.TabPane
                  tab={
                    <div className="tab-panel">
                      <span
                        className={`${currentTab === item?.key
                            ? "active-label"
                            : "normal-label"
                          }`}
                      >
                        {item.icon}
                        {item.label}
                      </span>
                    </div>
                  }
                  key={item.key}
                >
                  {item.component}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
    </>
  );
}
