import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';

export function BackButton(props) {
  const { title, history } = props;

  return (
    <div onClick={() => history.goBack()} className="header-back"><ArrowLeftOutlined style={{ marginTop: "-2px" }} />{title}</div>
  );
}

export function BackToPathButton(props) {
  const { title, path = "/", history } = props;

  return (
    <div onClick={() => history.push(path)} className="header-back"><ArrowLeftOutlined style={{ marginTop: "-2px" }} />{title}</div>
  );
}