import React from 'react'
import { FormattedMessage } from 'react-intl'

export const LOCATION = "LOCATION"
export const TYPE = "TYPE"
export const CHECKIN_CATEGORY = [
    {
        key: <FormattedMessage id="GO_IN_OUT"/>, //Ra vào (không phân biệt)
        value: 0,
    },
    {
        key: <FormattedMessage id="GO_IN"/>, //Ra
        value: 10,
    },
    {
        key: <FormattedMessage id="GO_OUT"/>, //Vào
        value: 20,
    },
    {
        key: <FormattedMessage id="CHECK_CHECK"/>, //Điểm danh
        value: 30,
    }
]

export const CHECKIN_CATEGORY_STR = {
    0: <FormattedMessage id="GO_IN_OUT"/>,
    10: <FormattedMessage id="GO_IN"/>,
    20: <FormattedMessage id="GO_OUT"/>,
    30: <FormattedMessage id="CHECK_CHECK"/>
}
