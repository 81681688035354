// nickname,
// phone,
// signedUp: Firebase.database.ServerValue.TIMESTAMP,
// lastLoggedIn: Firebase.database.ServerValue.TIMESTAMP,
// email: email,
// uid: res.user.uid,

const initialState = {
  isUserLoggedIn: !!window.localStorage.getItem('isUserLoggedIn'),
  uid: window.localStorage.getItem('uid') || '',
  email: window.localStorage.getItem('email') || '',
  nickname: window.localStorage.getItem('nickname') || '',
  role: window.localStorage.getItem('role') || '',
  phone: window.localStorage.getItem('phone') || '',
  imgUrl: window.localStorage.getItem('imgUrl') || '',
  job: window.localStorage.getItem('job') || '',
  address: window.localStorage.getItem('address') || '',
  groupIds: window.localStorage.getItem('groupIds') ? window.localStorage.getItem('groupIds').split(",") : [],
  followIds: window.localStorage.getItem('followIds') ? window.localStorage.getItem('followIds').split(",") : [],
}

export default function userReducer(state = initialState, action) {

  switch (action.type) {
    case 'USER_LOGIN': {
      if (action.data) {
        window.localStorage.setItem('userData', JSON.stringify(action.data))
        window.localStorage.setItem('accessToken', JSON.stringify(action.data.accessToken))
        window.localStorage.setItem('refreshToken', JSON.stringify(action.data.refreshToken))

        window.localStorage.setItem('isUserLoggedIn', true)
        window.localStorage.setItem('uid', action.data.uid)
        window.localStorage.setItem('email', action.data.email)
        window.localStorage.setItem('nickname', action.data.nickname)
        window.localStorage.setItem('phone', action.data.lastName)
        window.localStorage.setItem('role', action.data.role)
        window.localStorage.setItem('imgUrl', action.data.imgUrl)
        window.localStorage.setItem('job', action.data.job)
        window.localStorage.setItem('address', action.data.address)
        window.localStorage.setItem('groupIds', action.data.groupIds)
        window.localStorage.setItem('followIds', action.data.followIds)
        return {
          ...state,
          // ...action.data,
          imgUrl: action.data.imgUrl || "",
          uid: action.data.uid,
          email: action.data.email || '',
          role: action.data.role || '',
          isUserLoggedIn: true,
          phone: action.data.phone || '',
          nickname: action.data.nickname || '',
          signedUp: action.data.signedUp || '',
          groupIds: action.data.groupIds || [],
          job: action.data.job || '',
          address: action.data.address || '',
          followIds: action.data.followIds || []
        }
      }
      return {}
    }
    case 'USER_DETAILS_UPDATE': {
      if (action.data) {
        const data = {
          // ...action.data,
          imgUrl: action.data.imgUrl || "",
          uid: action.data.uid,
          email: action.data.email || '',
          isUserLoggedIn: true,
          phone: action.data.phone || '',
          nickname: action.data.nickname || '',
          signedUp: action.data.signedUp || '',
          groupIds: action.data.groupIds || [],
          role: action.data.role || '',
          job: action.data.job || '',
          address: action.data.address || '',
          followIds: action.data.followIds || []
        }

        if (action.data.role) {
          window.localStorage.setItem('role', action.data.role)
          data.role = action.data.role
        }

        window.localStorage.setItem('isUserLoggedIn', true)
        window.localStorage.setItem('uid', action.data.uid)
        window.localStorage.setItem('email', action.data.email)
        window.localStorage.setItem('nickname', action.data.nickname)
        window.localStorage.setItem('phone', action.data.lastName)
        window.localStorage.setItem('imgUrl', action.data.imgUrl)
        window.localStorage.setItem('job', action.data.job)
        window.localStorage.setItem('address', action.data.address)
        window.localStorage.setItem('groupIds', action.data.groupIds)
        window.localStorage.setItem('followIds', action.data.followIds)
        return {
          ...state,
          ...data
        }
      }
      return {}
    }
    case 'USER_RESET': {
      // window.localStorage.clear();

      // window.localStorage.setItem('tourguide', true);
      window.localStorage.removeItem('isUserLoggedIn')
      window.localStorage.removeItem('uid')
      window.localStorage.removeItem('email')
      window.localStorage.removeItem('nickname')
      window.localStorage.removeItem('role')
      window.localStorage.removeItem('phone')
      window.localStorage.removeItem('imgUrl')
      window.localStorage.removeItem('job')
      window.localStorage.removeItem('address')
      window.localStorage.removeItem('groupIds')
      window.localStorage.removeItem('followIds')
      return {}
    }
    default:
      return state
  }
}
