import React, { useState, useEffect } from 'react'
import { Table, notification } from 'antd'
import { COLUMN_CONFIG } from './columns'
import { REQUEST_CONFIG } from './../../../constants/request'
import { ENDPOINTS } from './../../../constants/endpoints'
import HistoryService from '../../../services/history'
import RequestService from '../../../services/request'

export default function UserCheckinHistory({ props }) {
    const { match } = props
    const { id = "" } = match.params
    const [visibleColumns, setVisibleColumns] = useState(COLUMN_CONFIG)
    const [selectedRows, setSelectedRows] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)

    const [selectedFilterValues, setSelectedFilterValues] = useState({})

    // // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (_, sltRows) => {
        setSelectedRows(sltRows);
        },
    };

    function fetchData() {
        setTableData([]);
        setLoading(true);

        RequestService.send({
            method: 'POST',
            path: 'order/getOrderDetail',
            data: { orderId: id }
          }).then((res = {}) => {
            const { data: newData } = res
            if (newData?.order_items) {
                if (newData?.order_items.length > 0) {
                    let ticket_id = newData?.order_items[0].ticketId;
                    HistoryService.getList(
                        {
                            [REQUEST_CONFIG.method]: "POST",
                            [REQUEST_CONFIG.path]: ENDPOINTS.CHECK_HISTORY_LIST,
                        },
                        {
                            ticketId: ticket_id
                        },
                        selectedFilterValues,
                        currentPage
                    )
                    .then((res) => {
                        if (res?.statusCode === 200) {
                            setTableData(res?.data.data);
                            setTotalRecords(res?.totalRecord);
                            setTotalPages(res?.totalPage);
                        }
                        setLoading(false);
                      })
                      .catch((error) => {
                        console.error('Error during fetch data:', error);
                        notification.error({ message: `${error.message}` });
                      });
                }
                setLoading(false);
            }
          });
    }

    // TODO: call api to get data
    useEffect(() => {
        fetchData();
    }, [currentPage, selectedFilterValues])

    return (
        <>
            <div className="d-flex flex-column align-items-center">
                <Table
                    id="rawResTable"
                    className="w-100 tableMobile center-table"
                    columns={visibleColumns.filter((item) => item.visible)}
                    dataSource={tableData}
                    rowKey={(record) => Object.values(Object.values(record)[0])[0]}
                    rowSelection={rowSelection}
                    pagination={false}
                    sticky
                    loading={loading}
                />
            </div>
        </>
    )
}